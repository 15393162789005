// export const FEATURED_IMAGE = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2Ffeatured_image.JPEG?alt=media&token=f41fe12b-a613-4bbc-a32f-d288c4d4b1f9"
export const FEATURED_IMAGE = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2FfeatureImageoptimized.jpg?alt=media&token=85843e00-18bf-4d57-84d8-7f4e748196d1"
export const GALLERYcENTRE_IMAGE = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2Fgallery_center.PNG?alt=media&token=a9f0358a-adab-4117-b244-3ab6b82c9a39"
export const MEETTHECOUPLE_IMAGE = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2Fmeetthecouple.jpg?alt=media&token=d8c40e47-ba76-40ca-8de3-1f5296dc130a"
export const GALLERY_VIDEO1 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2FIMG_0049.mp4?alt=media&token=eb918a44-2760-4f8c-9a63-50bb6bc32cae"
export const IMAGE_2037 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_2037.JPEG?alt=media&token=9ac64e90-0826-4e67-856f-73b06b7b6b37"
export const IMAGE_2968 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_2968.JPEG?alt=media&token=ea28a2f0-8cb7-4585-8fec-4e0cda098a60"
export const IMAGE_3216 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FIMG_3216.jpg?alt=media&token=c88cc8b8-845f-42b1-a630-c7a0ed7b26cc"
export const IMAGE_5170 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_5170.JPEG?alt=media&token=5c3ed5f9-094f-4064-abdb-4c9802ccbb48"
export const IMAGE_7617 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_7617_Original.JPEG?alt=media&token=b9562b9e-e434-4f10-8354-a0278f59aa29"
// export const POSTER = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FPoster2.jpg?alt=media&token=063fb070-24d8-4e18-9b0e-8b7979ed6493"
export const POSTER = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2FWhatsApp%20Image%202024-04-09%20at%2011.14.05_19fedd5a.jpg?alt=media&token=9d494b17-9eb1-46d4-b3ed-f30605fae64d"
export const GALLERY_IMG4 = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_6529.jpeg?alt=media&token=d88f0678-f836-40bd-9030-21f06e528e8d"
export const ShortsImage = "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FImages%2FOptimzed%2Fjpeg-optimizer_IMG_5170.JPEG?alt=media&token=5c3ed5f9-094f-4064-abdb-4c9802ccbb48"
// export const HEADERVIDEO= "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FVideo%2FFinal.mp4?alt=media&token=12c3d3aa-7ebd-4624-a16d-1b74430f9752"
export const HEADERVIDEO= "https://firebasestorage.googleapis.com/v0/b/tickiteasy-3c9ef.appspot.com/o/Wedding%2FVideo%2FNeesigovaregu.mp4?alt=media&token=104dac78-a5ec-421e-a023-c29e446da23f"